import React, { useEffect } from 'react';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  Page,
} from '../../components';
import logoImage from '../../assets/BuySell_LOGOS_blk&grey.png';
import css from './SplashPage.module.css';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { localBagData } from '../../util/dataExtractor';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';


export const SplashPageComponent = props => {
  const { location, scrollingDisabled,onManageDisableScrolling } = props;
  const lb = localBagData();
  const history = useHistory();
  const state = useSelector((state) => state);
  const { currentUser } = state.user;
  const topbar = <TopbarContainer lb={lb} />;

  useEffect(() => {
    setTimeout(() => {
      // if(!(currentUser?.id)){
      //   const state = {
      //     from: `${location.pathname}${location.search}${location.hash}`,
      //   };
      //   history.push(
      //     createResourceLocatorString('LandingPage', routeConfiguration(), {}, {}),
      //     state
      //   );
      // }else{
        history.push(
          createResourceLocatorString('LandingPage', routeConfiguration(), {}, {}),
        );
      // }
    }, 1000);
  }, []);

  return (
    <Page title={'SplashPage'} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.fixWidthContainer}>
            <Modal
              id="SplashPageModal"
              isOpen={true}
              onClose={() => {
              }}
              usePortal
              contentClassName={css.modalContent}
              onManageDisableScrolling={onManageDisableScrolling}
              className={css.modalSplash}
            >
              <div className={classNames(css.loading, css.loading02)}>
                <img src={logoImage} />
              </div>
              <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </Modal>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

SplashPageComponent.propTypes = {


}

const mapStateToProps = state => {

  return {

    scrollingDisabled: isScrollingDisabled(state),

  };
};
const mapDispatchToProps = dispatch => ({

  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});
const SplashPage = compose(

  connect(mapStateToProps, mapDispatchToProps),

)(SplashPageComponent);

export default SplashPage;
